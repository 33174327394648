import { z } from "zod";

export const newsletterSchema = z.object({
  email: z.string().email("Oops, please enter a valid email"),
});

export type NewsletterSchema = z.infer<typeof newsletterSchema>;

export const title = "Subscribe to our newsletter and never miss an article";
export const successMsg = "Great! We'll stay in touch.";
export const popoverLabel = "Information on data privacy and marketing";
export const popoverContent = `By subscribing, you agree to our <a href="https://virtuslab.com/privacy-policy/">Privacy Policy</a> governing the collection and use of your personal information for marketing purposes.`;
