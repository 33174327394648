import Icon from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC, useState } from "react";
import { useHighlightedNode } from "./TableOfContents.helpers";
import { TableOfContentsProps } from "./TableOfContents.types";

export const TableOfContents: FC<TableOfContentsProps> = ({
  title,
  headingNodes,
  className,
}) => {
  const highlightedId = useHighlightedNode(headingNodes);

  const maxHeadingNodesAmount = 3;
  const headingsLeft = headingNodes.length - maxHeadingNodesAmount;
  const [showAllHeadingNodes, setShowAllHeadingNodes] = useState(
    headingNodes.length <= maxHeadingNodesAmount || headingsLeft === 1
  );

  return (
    <div className={cn("flex-col", className)}>
      <Typography
        tag="p"
        variant="xs"
        weight="semibold"
        className="mb-4 uppercase text-grey-faded xl:mb-5"
      >
        {title}
      </Typography>
      <ul className="gap-4 text-xs font-medium xl:gap-[1.125rem] xl:text-sm">
        {headingNodes.map((item, ind) => (
          <li className="mt-4 first:mt-0 xl:mt-[1.125rem]" key={item.id}>
            <Link
              className={cn(
                "text-grey-mid transition-colors duration-300 hover:text-green-accent-two",
                item.id === highlightedId && "text-green-accent-two",
                !showAllHeadingNodes && ind >= maxHeadingNodesAmount
                  ? "hidden"
                  : ""
              )}
              data-disable-delay
              href={`#${item.id}`}
            >
              {item.text}
            </Link>
          </li>
        ))}
        {!showAllHeadingNodes && (
          <li
            className="inline-block"
            onClick={() => setShowAllHeadingNodes(true)}
          >
            <div className="flex cursor-pointer items-center gap-2 text-grey-mid transition-colors duration-100 hover:text-content-primary-on-light">
              <span>
                and{" "}
                <span className="underline underline-offset-2">
                  {headingsLeft} more chapters
                </span>
              </span>
              <Icon
                height={12}
                name="FloatingArrowLeft"
                className="translate-x-0.5 -rotate-90 xl:translate-y-px"
              />
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};
