"use client";

import { LightBox } from "@atoms/light-box/LightBox";
import { ShortcutsSection } from "@sections/shortcuts-section/ShortcutsSection";
import { ShortcutsCardProps } from "@sections/shortcuts-section/shortcuts-card/ShortcutsCard.types";
import { useImageLightbox } from "@utils/use-image-lightbox/useImageLightbox";
import { FC, useRef } from "react";
import { BlogLayoutProps } from "./BlogLayout.types";
import { BlogAside } from "./blog-aside/BlogAside";
import { BlogBottom } from "./blog-bottom/BlogBottom";

export const BlogLayout: FC<BlogLayoutProps> = ({
  navLinks,
  children,
  headingNodes,
  relatedArticles,
  articleTags,
  newsletter,
}) => {
  const articleRef = useRef<HTMLDivElement>(null);
  const { selectedImage, isLightboxOpen, closeLightbox } =
    useImageLightbox(articleRef);

  const shortcutCards: ShortcutsCardProps[] =
    relatedArticles?.slice(0, 3).map((it) => ({
      title: it.title,
      href: it.src,
      image: it.image,
    })) ?? [];

  return (
    <main className="relative">
      <LightBox
        url={selectedImage}
        onClick={closeLightbox}
        active={isLightboxOpen}
      />
      <div className="container-flexible lg:grid lg:grid-cols-12 lg:gap-x-5 2xl:gap-x-10">
        <article
          className="lg:col-span-8 2xl:col-span-6 2xl:col-start-2"
          ref={articleRef}
        >
          {children}
        </article>
        <div className="hidden lg:col-span-3 lg:col-start-10 lg:block 2xl:col-start-9">
          <BlogAside
            className="sticky top-28"
            headingNodes={headingNodes}
            relatedArticles={relatedArticles}
            articleTags={articleTags}
          />
        </div>
        <BlogBottom
          navLinks={navLinks}
          newsletter={newsletter}
          className="mb-12 mt-9 lg:col-span-8 xl:mb-16 2xl:col-span-6 2xl:col-start-2"
        />
      </div>
      <ShortcutsSection cards={shortcutCards} />
    </main>
  );
};
