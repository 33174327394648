import { TableOfContents } from "@atoms/table-of-contents/TableOfContents";
import { ArticlesList } from "@molecules/articles-list/ArticlesList";
import { Tags } from "@molecules/tags/Tags";
import { SimpleNode } from "@utils/dom/parsed-html";
import { cn, tw } from "@utils/tailwind";
import { FC } from "react";
import { ArticleTag } from "@/types/article";
import { ArticleProps } from "../BlogLayout.types";
import styles from "./BlogAside.module.css";

type BlogAsideProps = {
  headingNodes: SimpleNode[];
  relatedArticles?: ArticleProps[];
  articleTags?: ArticleTag[];
  className?: string;
};

export const BlogAside: FC<BlogAsideProps> = ({
  headingNodes,
  relatedArticles,
  articleTags,
  className,
}) => {
  const itemBorderBottomStyles = tw`mb-6 border-b border-grey-scale-light-gray pb-6 xl:mb-7 xl:pb-7`;

  return (
    <aside className={className}>
      <div
        className={cn(
          "flex max-h-[calc(100vh-155px)] flex-col overflow-y-auto pr-0.5",
          styles.scrollbar
        )}
      >
        {headingNodes.length > 0 && (
          <TableOfContents
            title="In this article"
            headingNodes={headingNodes}
            className={itemBorderBottomStyles}
          />
        )}
        {relatedArticles && relatedArticles.length > 0 && (
          <ArticlesList
            title="Related articles"
            articles={relatedArticles}
            className={itemBorderBottomStyles}
          />
        )}
        {articleTags && articleTags.length > 0 && (
          <Tags title="Article tags" tags={articleTags} />
        )}
      </div>
    </aside>
  );
};
