import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import { Loader } from "@atoms/loader/Loader";
import { cn } from "@utils/tailwind";
import { FC, useEffect, useState } from "react";
import { useCallbackOnEsc } from "./LightBox.helpers";
import { LightBoxProps } from "./LightBox.types";

export const LightBox: FC<LightBoxProps> = ({ url, onClick, active }) => {
  useCallbackOnEsc(onClick, active);
  const activeStyles = cn(
    active ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"
  );
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => setIsLoaded(false), [url]);

  return (
    <div
      className={cn(
        "ease fixed left-0 top-0 z-[100000] flex h-screen w-screen cursor-pointer items-center justify-center bg-black/80 transition-opacity duration-500",
        activeStyles
      )}
      onClick={onClick}
    >
      <Icon
        className="ease absolute right-5 top-5 size-4 fill-content-primary-on-dark opacity-30 transition-opacity duration-500 hover:opacity-60"
        name="Error"
      />
      {url && !isLoaded && (
        <Loader color="white" className="absolute left-1/2 top-1/2 size-10" />
      )}
      {url && (
        // Note: key={url} is important here, otherwise it will show previously
        // loaded image when url changes
        <Image
          key={url}
          className="h-auto max-h-[85vh] max-w-[85vw] bg-white"
          onLoad={() => setIsLoaded(true)}
          imageData={{ attributes: { url } }}
          src={url}
          alt=""
        />
      )}
    </div>
  );
};
