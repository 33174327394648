import { NavLinks } from "@layout/blog-layout/BlogLayout.types";
import Link from "next/link";
import { FC } from "react";

export const BlogNav: FC<NavLinks> = ({ prev, next }) =>
  (prev || next) && (
    <div className="flex justify-between py-3 font-termina text-xs font-medium text-content-primary-on-light">
      {prev && (
        <Link
          href={prev.slug}
          className="underline-offset-2 hover:underline active:text-green-accent-two"
        >
          Previous article
        </Link>
      )}
      {next && (
        <Link
          href={next.slug}
          className="underline-offset-2 hover:underline active:text-green-accent-two"
        >
          Next article
        </Link>
      )}
    </div>
  );
