import { NavLinks } from "@layout/blog-layout/BlogLayout.types";
import { Newsletter } from "@layout/footer/Footer.types";
import { BlogNewsletter } from "@sections/blog-newsletter/BlogNewsletter";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { BlogNav } from "./BlogNav";

type BlogBottomProps = {
  newsletter?: Newsletter;
  navLinks?: NavLinks;
  className?: string;
};

export const BlogBottom: FC<BlogBottomProps> = ({
  newsletter,
  navLinks,
  className,
}) => (
  <div className={cn("flex flex-col gap-10 md:gap-12 xl:gap-16", className)}>
    <BlogNav {...navLinks} />
    <BlogNewsletter newsletter={newsletter} />
  </div>
);
