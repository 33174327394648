import { Button } from "@atoms/button/Button";
import Icon from "@atoms/icon/Icon";
import { TextField } from "@atoms/text-field/TextField";
import Typography from "@atoms/typography/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { Newsletter } from "@layout/footer/Footer.types";
import { NewsletterPopover } from "@molecules/newsletter-popover/NewsletterPopover";
import { subscribeNewsletter } from "@utils/api/virtuslab/newsletter";
import { cn, tw } from "@utils/tailwind";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  NewsletterSchema,
  newsletterSchema,
  popoverContent,
  popoverLabel,
  successMsg,
  title,
} from "./BlogNewsletter.helpers";

const titleStyles = tw`mb-11
  font-termina
  text-xl
  font-medium
  leading-7
  text-content-primary-on-light
  max-2xl:text-balance
  xl:text-2xl`;

export const BlogNewsletter: FC<{
  newsletter?: Newsletter;
}> = ({ newsletter }) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm<NewsletterSchema>({
    resolver: zodResolver(newsletterSchema),
  });

  const error = errors.email?.message ?? errors.root?.serverError?.message;

  const submit: SubmitHandler<NewsletterSchema> = async (values) => {
    const result = await subscribeNewsletter({ email: values.email });

    if (!result.success) {
      const suffix = result.errorMessage
        ? result.errorMessage
        : "try again later";
      setError("root.serverError", {
        type: "server",
        message: `Oops, something is glitched: ${suffix}`,
      });
    }
  };

  return (
    <div className="rounded-2xl bg-blue-light p-6 md:p-7 xl:p-9">
      <h2 className={titleStyles}>{title}</h2>
      <form
        noValidate
        className={cn(
          "flex flex-col gap-6 md:flex-row md:justify-between md:gap-12 xl:gap-16",
          isSubmitSuccessful ? "mb-4" : "mb-5 md:mb-6 xl:mb-7"
        )}
        onSubmit={handleSubmit(submit)}
      >
        <TextField
          required
          id="newsletter-email"
          type="email"
          label={newsletter?.inputPlaceholder ?? "Company e-mail"}
          className="w-full md:pt-1.5 lg:pt-1 xl:pt-5"
          error={error}
          disabled={isSubmitSuccessful}
          {...register("email")}
        />
        <Button
          iconShowOnMobile
          type="submit"
          intent="primaryV2"
          icon="ArrowRight"
          loaderSize="lg"
          loading={isSubmitting}
          disabled={isSubmitSuccessful}
          className={isSubmitSuccessful ? "hidden" : "h-fit sm:block"}
        >
          Subscribe
        </Button>
        <Icon
          name="Thick"
          className={cn(
            "hidden size-4 flex-none self-center text-green-accent-two xl:mt-4",
            isSubmitSuccessful && "md:block"
          )}
        />
      </form>
      <Typography
        tag="p"
        variant="sm"
        className={cn(
          "mb-5 text-green-accent-two md:mb-6 xl:mb-7",
          isSubmitSuccessful ? "block" : "hidden"
        )}
      >
        {successMsg}
      </Typography>
      <NewsletterPopover
        label={newsletter?.linkLabel ?? popoverLabel}
        content={newsletter?.tooltip ?? popoverContent}
      />
    </div>
  );
};
