"use client";

import Icon from "@atoms/icon/Icon";
import { cn } from "@utils/tailwind";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useEffect, useState } from "react";

export const CopyButton: FC<{ copiedText: string; className?: string }> = ({
  copiedText,
  className,
}) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setCopied(false);
    }, 1500);
    return () => clearTimeout(id);
  }, [copied]);

  const copy = () => {
    void navigator.clipboard.writeText(copiedText);
    setCopied(true);
  };
  return (
    <button
      onClick={copy}
      className={cn(
        "flex min-h-6 items-center gap-2 self-end text-grey-scale-light-gray opacity-70 transition-opacity hover:opacity-100",
        className
      )}
    >
      <span className="relative size-6">
        <AnimatePresence>
          {copied && (
            <motion.span
              key="thick"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="absolute inset-0"
            >
              <Icon name="Thick" className="mt-0.5 size-4" />
            </motion.span>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {!copied && (
            <motion.span
              key="copy"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="absolute inset-0"
            >
              <Icon name="Copy" className="fill-transparent" />
            </motion.span>
          )}
        </AnimatePresence>
      </span>
      <span className="font-termina text-xs font-medium">Copy</span>
    </button>
  );
};
