import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { FC } from "react";
import { ShortcutsSectionProps } from "./ShortcutsSection.types";
import { ShortcutsCard } from "./shortcuts-card/ShortcutsCard";

const title = "Explore more topics";

export const ShortcutsSection: FC<ShortcutsSectionProps> = ({ cards }) => (
  <ContainerSpacing
    withBackground
    className="bg-organic-lighter text-content-primary-on-light"
  >
    <div className="container-flexible grid 2xl:grid-cols-12 2xl:gap-x-10">
      <h2 className="mb-9 text-xl font-medium empty:hidden lg:mb-10 xl:mb-14 xl:text-2xl 2xl:col-span-10 2xl:col-start-2">
        {title}
      </h2>
      <div className="grid gap-6 empty:hidden md:grid-cols-3 md:gap-7 lg:gap-9 xl:gap-16 2xl:col-span-10 2xl:col-start-2 2xl:gap-28">
        {cards?.map((it, ind) => (
          <ShortcutsCard key={`${it.title} ${ind}`} {...it} />
        ))}
      </div>
    </div>
  </ContainerSpacing>
);
