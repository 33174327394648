import Typography from "@atoms/typography/Typography";
import Link from "next/link";
import { FC } from "react";
import { ArticlesListProps } from "./ArticlesList.types";

export const ArticlesList: FC<ArticlesListProps> = ({
  title,
  className,
  articles,
}) =>
  articles &&
  articles.length > 0 && (
    <div className={className}>
      <Typography
        tag="p"
        variant="xs"
        weight="semibold"
        className="mb-4 uppercase text-grey-faded xl:mb-5"
      >
        {title}
      </Typography>
      <ul className="flex flex-col gap-[1.125rem]">
        {articles.map((article, index) => (
          <li
            key={`${article.title}-${index}`}
            className="text-xs font-medium text-grey-mid underline underline-offset-2 transition-colors duration-100 hover:text-content-primary-on-light xl:text-sm"
          >
            <Link href={article.src ?? ""}>{article.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
