import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import { cn, tw } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";
import { ShortcutsCardProps } from "./ShortcutsCard.types";

const imageWrapperStyles = tw`relative
  mb-2.5
  aspect-[2.25]
  h-auto
  w-full
  overflow-hidden
  rounded-2xl
  group-focus/card:outline
  md:mb-4
  md:aspect-[1.25]
  lg:aspect-[1.68]
  xl:mb-6
  xl:aspect-[1.87]
  2xl:mb-5
  2xl:aspect-[1.97]`;

const imageStyles = tw`scale-[1.02]
  transition-transform
  duration-300
  ease-out
  group-hover/card:-translate-y-0.5
  group-hover/card:translate-x-1
  group-hover/card:scale-105`;

const iconWrapperStyles = tw`absolute
  bottom-3
  right-3
  grid
  size-[45px]
  place-items-center
  rounded-full
  border
  border-current
  text-content-primary-on-dark
  transition-colors
  duration-500
  ease-out
  group-hover/card:border-green-accent-two
  group-hover/card:bg-green-accent-two
  xl:bottom-4
  xl:right-4`;

const titleStyles = tw`text-base
  font-medium
  underline
  decoration-transparent
  underline-offset-4
  transition-colors
  duration-500
  ease-out
  group-hover/card:decoration-current
  group-focus/card:decoration-current
  xl:text-lg`;

export const ShortcutsCard: FC<ShortcutsCardProps> = ({
  title,
  href,
  image,
}) => (
  <Link href={href ?? ""} className="group/card outline-none">
    <div className={imageWrapperStyles}>
      <Image backgroundImage imageData={image} className={imageStyles} />
      <div className={cn(iconWrapperStyles, !href && "hidden")}>
        <Icon
          name="ArrowTopRight"
          className="size-[11px] transition-colors duration-500 ease-out"
        />
      </div>
    </div>
    <h3 className={titleStyles}>{title}</h3>
  </Link>
);
