import Typography from "@atoms/typography/Typography";
import Link from "next/link";
import { FC } from "react";
import { TagsProps } from "./Tags.types";

export const Tags: FC<TagsProps> = ({ title, tags }) => (
  <div>
    <Typography
      tag="p"
      variant="xs"
      weight="semibold"
      className="mb-4 uppercase text-grey-faded xl:mb-5"
    >
      {title}
    </Typography>
    <ul className="flex flex-wrap gap-x-2.5 gap-y-2">
      {tags.map((tag, index) => (
        <li
          key={`tag-${index}`}
          className="cursor-pointer text-xs font-medium text-grey-mid transition-colors duration-100 hover:text-content-primary-on-light xl:text-sm"
        >
          <Link
            href={`/blog/tag/${tag.slug}`}
            id={index === 0 ? `tag` : `tag-${index}`}
          >
            {`#${tag.title}`}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
